.buttons {
    display: flex;
    gap: 8px;
}

.canceled {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -55%) rotate(-3deg);
    width: 300px;
    height: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px; 
    border: 4px solid red;
    z-index: 10;
    filter: drop-shadow(-2px 2px 5px rgba(0, 0, 0, .5));
}

.canceled p {
    font-size: 38px;
     color: #d70808;
}

.table p {
    flex-wrap: wrap;
    word-break: break-all;
    max-width: 90%;
    text-align: center;
}

.cells {
    padding: 16px 24px;
}

.chainbtns {
    display: flex;
    gap: 30px;
    align-items: center;
}

.chainbtns button {
    max-width: 185px;
}

.smlogo {
    display: none;
}

.contract {
    position: absolute;
    top: -70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    gap: 2px;
    margin: 0px 50px;
    box-sizing: border-box;
    max-width: 80vw;
    width: auto;
}
.contract a {
    border: 2px dashed grey;
    font-weight: normal;
    text-overflow: ellipsis;
    background-color: transparent;
    overflow: hidden; 
    white-space: nowrap;
    margin: 0px 50px;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 40px;
    padding: 3px 12px 2px 12px;
    transition: .25s ease-in-out background-color, color, border;
}
.contract a:hover, .contract a:focus-visible {
    color: white;
    background-color: black;
    border: 2px solid black;
}

@media screen and (max-width: 470px) {
    .buttons {
        padding-top: 20px;
        flex-direction: column;
        gap: 20px;
    }
    .table span {
        width: 38px;
        height: 38px;
    }
    .cells {
        padding: 16px 0px;
    }
}

@media screen and (max-width: 550px) {
    .canceled {
        width: 200px;
        height: 70px;
        border: 3px solid red;
        border-radius: 20px;
    }
    .canceled p {
        font-size: 28px;
    }
    .cells {
        font-size: 14px;
    }
    .chainbtns {
        flex-direction: column;
    }
    .lglogo {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .smlogo {
        display: flex;
    }
}
